import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import MembersItem from "./Members/MembersItem";

const MembersFlex = styled.div`
  width: 100%;

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    div {
      width: 33.3%;
    }
  }

  /* @media screen AND (min-width: ${breakpoints.desktop}px) {
    div {
      width: 33.3%;
    }
  } */
`;

const Members = () => (
  <MembersFlex>
    <MembersItem />
    <MembersItem />
    <MembersItem />
    <MembersItem />
    <MembersItem />
  </MembersFlex>
);

export default Members;
