import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { breakpoints, colors } from "../../styles/variables";

const RouteElem = styled.article`
  max-width: 288px;
  margin: 0 auto 40px;
  padding-bottom: 10px;
  border-bottom: 4px solid ${colors.blue};
  a {
    text-decoration: none;
  }
  .title {
    padding: 10px 10px 20px;
    background: ${colors.blue};
    border-bottom-right-radius: 30px;
    width: 288px;
    margin-bottom: 20px;

    h5 {
      font: 18px / 24px "Slate Medium";
      text-transform: uppercase;
      color: white;
    }
  }
  p {
    font: 18px / 24px "Slate Light";
  }
  .image {
    background: url("http://placekitten.com/200/300");
    width: 226px;
    height: 205px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto 15px;
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding-bottom: 13px;
    margin-bottom: 0;

    .title {
      margin-bottom: 28px;
      h5 {
        font: 24px / 29px "Slate Medium";
      }
    }
    .image {
      margin-bottom: 22px;
    }
    p {
      font: 24px / 30px "Slate Light";
    }
  }
`;

const Route = () => (
  <RouteElem>
    <Link to="#">
      <div className="title">
        <h5>Exam Route</h5>
      </div>
      <div className="image" />
      <p>
        Accumulate 240 credits through completing qualifications and
        demonstrating relevant experience. As the requisite levels of experience
        and qualification are reached, you can move up through our membership
        framework to reach the top level of membership - the TEP.
      </p>
    </Link>
  </RouteElem>
);

export default Route;
