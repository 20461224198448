import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const SponsorsElem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 30px;
  margin-bottom: 69px;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    gap: 60px 100px;
  }
`;

const Sponsor = styled.a`
  display: block;
  margin-bottom: 110px;
  img {
    max-height: 70px;
    height: 100%;
  }
`;

// 30/20 100/60

const SponsorsSmaller = () => (
  <SponsorsElem>
    <Sponsor href="sponsorLink" target="_blank">
      <img src="http://placekitten.com/600/200" alt="Sponsor Name" />
    </Sponsor>
    <Sponsor href="sponsorLink" target="_blank">
      <img src="http://placekitten.com/600/200" alt="Sponsor Name" />
    </Sponsor>
    <Sponsor href="sponsorLink" target="_blank">
      <img src="http://placekitten.com/600/200" alt="Sponsor Name" />
    </Sponsor>
    <Sponsor href="sponsorLink" target="_blank">
      <img src="http://placekitten.com/600/200" alt="Sponsor Name" />
    </Sponsor>
    <Sponsor href="sponsorLink" target="_blank">
      <img src="http://placekitten.com/600/200" alt="Sponsor Name" />
    </Sponsor>
    <Sponsor href="sponsorLink" target="_blank">
      <img src="http://placekitten.com/600/200" alt="Sponsor Name" />
    </Sponsor>
    <Sponsor href="sponsorLink" target="_blank">
      <img src="http://placekitten.com/600/200" alt="Sponsor Name" />
    </Sponsor>
    <Sponsor href="sponsorLink" target="_blank">
      <img src="http://placekitten.com/600/200" alt="Sponsor Name" />
    </Sponsor>
    <Sponsor href="sponsorLink" target="_blank">
      <img src="http://placekitten.com/600/200" alt="Sponsor Name" />
    </Sponsor>
  </SponsorsElem>
);

export default SponsorsSmaller;
