import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const Dates = styled.div`
  margin-bottom: 60px;
  td,
  td p {
    font: 18px / 24px "Slate Light";
  }
  tr {
    padding-bottom: 16px;
  }

  tr > td:first-of-type {
    font: 18px / 24px "Slate Medium";
    padding-right: 20px;
    vertical-align: top;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 104px;

    td,
    td p {
      font: 21px / 30px "Slate Light";
    }
    tr {
      padding-bottom: 24px;
    }

    tr > td:first-of-type {
      font: 21px / 30px "Slate Medium";
      padding-right: 57px;
    }
  }
`;

const DatesTable = () => (
  <Dates>
    <table>
      <thead />
      <tbody>
        <tr>
          <td>1981</td>
          <td>
            Foundation of Verein STEP (former Swiss German and Liechtenstein
            Branch of STEP) includes: Zurich Centre, Vaduz Centre, Lugano Centre
          </td>
        </tr>
        <tr>
          <td>1981</td>
          <td>
            Foundation of Verein STEP (former Swiss German and Liechtenstein
            Branch of STEP) includes: Zurich Centre, Vaduz Centre, Lugano Centre
          </td>
        </tr>
        <tr>
          <td>1981</td>
          <td>
            Foundation of Verein STEP (former Swiss German and Liechtenstein
            Branch of STEP) includes: Zurich Centre, Vaduz Centre, Lugano Centre
          </td>
        </tr>
        <tr>
          <td>1981</td>
          <td>
            Foundation of Verein STEP (former Swiss German and Liechtenstein
            Branch of STEP) includes: Zurich Centre, Vaduz Centre, Lugano Centre
          </td>
        </tr>
      </tbody>
    </table>
  </Dates>
);

export default DatesTable;
