import React from "react";
import styled from "styled-components";
import { breakpoints, spacers } from "../../styles/variables";

const WrapperDiv = styled.div`
  padding: 0 ${spacers.sides.mobile}px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 0 ${spacers.sides.desktop}px;
  }
`;

const TextContentWrapper = ({ children }) => (
  <WrapperDiv>{children}</WrapperDiv>
);

export default TextContentWrapper;
