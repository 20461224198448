import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";
import SectionTitle from "./Text/SectionTitle";
import ContentWrapper from "./Wrappers/ContentWrapper";

const BGElem = styled.a`
  display: block;
  padding: 30px 0;
  text-decoration: none;
  background: ${colors.lightBlue};
  text-align: center;
  h2 {
    color: white;
    margin-bottom: 60px;
  }

  img {
    margin: 0 auto;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 60px 0 110px;
  }
`;

const LightBlueTitleBG = () => (
  <BGElem href="#">
    <ContentWrapper>
      <SectionTitle>SLSF ORGANISATIONS CHART 2019</SectionTitle>
      <img src="/assets/gestaltungselemente/new-tab.svg" alt="neues Tab icon" />
    </ContentWrapper>
  </BGElem>
);

export default LightBlueTitleBG;
