import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

const InhaltElem = styled.article`
  margin-bottom: 50px;
  p,
  li {
    font: 18px / 24px "Slate Light";
  }

  p {
    margin-bottom: 30px;
  }

  b,
  strong {
    font-family: "Slate Medium";
  }

  li {
    margin-bottom: 8px;
  }

  ul,
  ol {
    margin-bottom: 50px;
  }

  a {
    color: ${colors.lightBlue};
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  &.center {
    text-align: center;
    margin-bottom: 80px;
    img {
      margin: 0 auto;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 81px;

    p,
    li {
      font: 21px / 30px "Slate Light";
    }
    p {
      margin-bottom: 40px;
    }

    ul,
    ol {
      margin-bottom: 60px;
    }
    li {
      margin-bottom: 15px;
    }

    &.center {
      margin-bottom: 110px;
    }
  }
`;

const Inhalt = ({ content, center }) => (
  <InhaltElem
    className={center ? "center" : ""}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

export default Inhalt;
