import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const TitleElem = styled.h1`
  font: 42px / 50px "Slate Medium";
  margin-bottom: 30px;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    font: 72px / 86px "Slate Medium";
    margin-bottom: 40px;
  }
`;

const MainTitle = ({ children }) => <TitleElem>{children}</TitleElem>;

export default MainTitle;
