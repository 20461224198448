import { graphql, StaticQuery } from "gatsby";

import React from "react";
import { Page } from "../components/Page";
import DefaultLayout from "../layouts/default";
import MainTitle from "../components/Text/MainTitle";
import SmallerMainTitle from "../components/Text/SmallerMainTitle";
import SectionTitle from "../components/Text/SectionTitle";
import SmallerSectionTitle from "../components/Text/SmallerSectionTitle";
import Inhalt from "../components/Text/Inhalt";
import SmallerInhalt from "../components/Text/SmallerInhalt";
import ContentWrapper from "../components/Wrappers/ContentWrapper";
import Events from "../components/Events";
import DatesTable from "../components/DatesTable";
import TextContentWrapper from "../components/Wrappers/TextContentWrapper";
import Members from "../components/Members";
import Membership from "../components/Membership";
import Publications from "../components/Publications";
import Button from "../components/Buttons/Button";
import GreyBox from "../components/GreyBox";
import Grid75 from "../components/Grid.tsx/Grid75";
import Grid47 from "../components/Grid.tsx/Grid47";
import Sponsors from "../components/Sponsors";
import FWBackground from "../components/FWBackground";
import { colors } from "../styles/variables";
import SponsorsSmaller from "../components/SponsorsSmaller";
import LightBlueTitleBG from "../components/LightBlueTitleBG";
import EventData from "../components/Events/EventData";
import FWBGContainer from "../components/FWBGContainer";

const PageTemplate = () => (
  <DefaultLayout>
    <Page>
      <TextContentWrapper>
        <MainTitle>
          Main Title <b>Bold</b>
        </MainTitle>
        <SmallerMainTitle>
          Smaller Main Title <b>Bold</b>
        </SmallerMainTitle>
        <SectionTitle>
          Section Title <b>Bold</b>
        </SectionTitle>
        <SmallerSectionTitle>
          Section Title <b>Bold</b>
        </SmallerSectionTitle>
        <Inhalt />
        <SmallerInhalt />
      </TextContentWrapper>
      <TextContentWrapper>
        <DatesTable />
      </TextContentWrapper>
      <ContentWrapper>
        <Members />
      </ContentWrapper>
      <ContentWrapper>
        <Membership />
      </ContentWrapper>
      <ContentWrapper />

      <ContentWrapper>
        <SponsorsSmaller />
      </ContentWrapper>
      <LightBlueTitleBG />
      <ContentWrapper />
    </Page>
  </DefaultLayout>
);
export default PageTemplate;
