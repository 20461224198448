import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const TitleElem = styled.h3`
  text-align: center;
  text-transform: uppercase;
  font: 30px / 36px "Slate Light";
  margin-bottom: 40px;
  margin-top: -16px;

  strong,
  b {
    font-family: "Slate Medium";
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    font: 44px / 53px "Slate Light";
    margin-bottom: 60px;
  }
`;

const SmallerSectionTitle = ({ children }) => <TitleElem>{children}</TitleElem>;

export default SmallerSectionTitle;
