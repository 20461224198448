import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import Route from "./Membership/Route";

const MembershipElem = styled.div`
  margin-bottom: 50px;
  @media screen AND (min-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 100px;
    margin-bottom: 80px;
  }
`;

const Membership = () => (
  <MembershipElem>
    <Route />
    <Route />
    <Route />
  </MembershipElem>
);

export default Membership;
